import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/layout'),
    children: [
      { path: '', name: 'criteria', component: () => import('../views/criteria') },
      { path: 'result', name: 'result', component: () => import('../views/result') },
      { path: 'cart/:cartCode?', name: 'cart', component: () => import('../views/cart') },
      { path: 'faq', name: 'faq', component: () => import('../views/faq') },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
