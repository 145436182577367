import moment from 'moment-mini';
import api from '@/api';

const CONTACT_FORM = {
  person: {
    label: 'Ansprechpartner',
    required: 'Bitte geben Sie den Ansprechpartner ein',
    validate: (value) => ((value.length < 2) ? 'Bitte geben Sie mindestens 2 Buchstaben ein' : ''),
  },
  firm: {
    label: 'Firma',
    required: 'Bitte geben Sie die Firma ein',
    validate: (value) => ((value.length < 2) ? 'Bitte geben Sie mindestens 2 Buchstaben ein' : ''),
  },
  address: {
    label: 'Adresse',
  },
  zipcity: {
    label: 'PLZ/Stadt',
  },
  telefon: {
    label: 'Telefon',
    required: 'Bitte geben Sie die Telefonnummer ein',
    // validate: (value) => (
    //   /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/.test(value)
    //     ? ''
    //     : 'Telefonnummer ist nicht korrekt'
    // ),
  },
  email: {
    label: 'E-Mail',
    required: 'Bitte geben Sie die E-Mail Adresse ein',
    validate: (value) => (
      // eslint-disable-next-line max-len
      /^[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/.test(value)
        ? ''
        : 'E-Mail ist nicht korrekt'
    ),
  },
  message: {
    type: 'textarea',
  },
};

const INITIAL_REQUEST_TYPE = 'offer';

export default {
  state: {
    contactForm: CONTACT_FORM,
    optionType: INITIAL_REQUEST_TYPE,
    options: {
      places: { offer: null, binding: null },
      print: { offer: null, binding_with_replacement: null, binding_without_replacement: null },
    },
  },
  getters: {
    contactForm: (state) => state.contactForm,
    contactFormKeys: (_, getters) => Object.keys(getters.contactForm),
    contactFormValues: (_, getters) => Object.fromEntries(
      Object.entries(getters.contactForm).map(([key, { value = null }]) => [key, value]),
    ),
    contactFormErrors: (_, getters) => getters.contactFormKeys.reduce((acc, key) => {
      const { value = null, required, validate = () => '' } = getters.contactForm[key];
      let error = required && !value
        ? required
        : validate(value);

      if (value === null) error = '';

      return { ...acc, [key]: error };
    }, {}),
    requestType: (state) => state.optionType,
    requestOptions: (state) => state.options,
    requestOptionsError: (_, getters) => {
      const type = getters.requestType;
      const options = getters.requestOptions;
      const optionsByType = {
        offer: [options.places.offer, options.print.offer],
        binding: [
          options.places.binding,
          options.print.binding_with_replacement,
          options.print.binding_without_replacement,
        ],
      };

      if (optionsByType[type].every((opt) => opt == null)) return '';

      if (optionsByType[type].every((opt) => !opt)) {
        return 'Bitte treffen Sie eine Auswahl';
      }

      return '';
    },
    cartRequest: (_, getters) => Object.fromEntries(Object.values(getters.cartData)
      .filter(({ marker, selectedDwm, dateRange }) => marker && (selectedDwm?.length || dateRange))
      .map(({ marker, selectedDwm, dateRange }) => {
        // eslint-disable-next-line object-curly-newline
        const { onr, tp, tpNative, au, sto, pos, block, qid } = marker;
        // eslint-disable-next-line object-curly-newline
        const markerData = { onr, tp, tp_native: tpNative, au, sto, pos, block, qid };

        const markerRequest = selectedDwm?.length
          ? selectedDwm.map((dwm) => {
            const { year, number } = dwm;
            const decadeSum = getters.getMarkerDwmCartSum({ marker, dwm });
            return {
              year,
              decade: number,
              decade_sum: decadeSum,
              ...markerData,
            };
          }) : [{
            year: getters.actualYears[0],
            decade_sum: 0,
            decade: 'ANFRAGE',
            from_to: { von: dateRange[0], bis: dateRange[1] },
            ...markerData,
            block: dateRange.join('-') || '-',
          }];

        return [marker.id, markerRequest];
      })),
  },
  actions: {
    updateContactForm({ commit }, { key, value }) {
      commit('UPDATE_CONTACT_FORM', { key, value });
    },
    updateRequestType({ commit }, type) {
      commit('UPDATE_REQUEST_TYPE', type);
    },
    updateRequestOptions({ commit }, options) {
      commit('UPDATE_REQUEST_OPTIONS', options);
    },
    clearCartFormErrors({ getters, commit }) {
      getters.contactFormKeys.forEach((key) => {
        if (!getters.contactFormValues[key]) {
          commit('UPDATE_CONTACT_FORM', { key, value: null });
        }
      });

      const requestOptions = JSON.parse(JSON.stringify(
        getters.requestOptions,
        (key, value) => (value instanceof Object ? value : (value || null)),
      ));

      commit('UPDATE_REQUEST_OPTIONS', requestOptions);
    },
    clearCartForm({ getters, commit }) {
      getters.contactFormKeys.forEach((key) => {
        commit('UPDATE_CONTACT_FORM', { key, value: null });
      });

      const requestOptions = JSON.parse(JSON.stringify(
        getters.requestOptions,
        (key, value) => (value instanceof Object ? value : null),
      ));

      commit('UPDATE_REQUEST_OPTIONS', requestOptions);

      commit('UPDATE_REQUEST_TYPE', INITIAL_REQUEST_TYPE);
    },
    validateCartForm({ getters, commit }) {
      getters.contactFormKeys.forEach((key) => {
        if (getters.contactForm[key].value == null) {
          commit('UPDATE_CONTACT_FORM', { key, value: '' });
        }
      });

      const requestOptions = JSON.parse(JSON.stringify(
        getters.requestOptions,
        (key, value) => value || false,
      ));

      commit('UPDATE_REQUEST_OPTIONS', requestOptions);
    },
    submitRequest({ getters, dispatch }) {
      const optionType = getters.requestType;

      // We need to set all options for another  optionType to false (for API)
      const options = JSON.parse(JSON.stringify(
        getters.requestOptions,
        (key, value) => (!(value instanceof Object) && !key.includes(optionType) ? false : (value || false)),
      ));

      const payload = {
        contact_form: {
          ...getters.contactFormValues,
          optionType,
          options,
        },
        selected_decades: getters.cartRequest,
      };

      return api.submitRequest(payload).then(() => {
        dispatch('clearCart');
        dispatch('clearCartForm');
      });
    },
    getCsv({ getters }) {
      const payload = {
        selected_decades: getters.cartRequest,
      };
      return api.getRequestCsv(payload).then((data) => {
        const filename = `Standort-Tool_Anfrage_vom_${moment().format('DD_MM_YYYY')}.csv`;
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
  mutations: {
    UPDATE_CONTACT_FORM(state, { key, value }) {
      const newItem = { ...state.contactForm[key], value };
      state.contactForm = {
        ...state.contactForm,
        [key]: newItem,
      };
    },
    UPDATE_REQUEST_TYPE(state, type) {
      state.optionType = type;
    },
    UPDATE_REQUEST_OPTIONS(state, options) {
      state.options = options;
    },
  },
};
