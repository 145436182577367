import api from '@/api';

export default {
  state: {
    minSearchCitySymbols: 3,
  },
  getters: {},
  actions: {
    searchCities(_, term) {
      const params = { term };

      return api.searchCities({ params }).then((options) => options.sort((a, b) => (a.ortbez > b.ortbez ? 1 : -1)));
    },
    getCityByOrtnr(_, ortnr) {
      const params = { schlnr: ortnr };

      return api.searchCities({ params }).then(([city]) => city);
    },
  },
  mutations: {},
};
