// load image icons for media
const filePaths = require.context('@/assets/img/media', false, /\.(svg|png)$/);
const images = {};

filePaths.keys().forEach((path) => {
  const imageName = path.replace(/(\.\/|\.(svg|png))/g, '');

  images[imageName] = filePaths(path);
});

const MEDIA_ALLOWED = process.env.VUE_APP_ALLOWED_MEDIA?.split(',');

const MEDIA_SETTINGS = [
  {
    type: 'GF', name: 'Großfläche', allowOffer: false, additional: ['CP'],
  },
  { type: 'GS', name: 'Ganzsäule', allowOffer: true },
  { type: 'CL', name: 'City-Light-Poster', allowOffer: true },
  { type: 'GD', name: 'Videoboards', allowOffer: true },
];

export default {
  state: {
    mediaSet: MEDIA_SETTINGS
      .filter(({ type }) => MEDIA_ALLOWED.includes(type))
      .map((media) => ({
        ...media,
        image: images[media.type],
        image_banner: images[`${media.type}_banner`],
        mappin: images[`${media.type}_mappin`],
        mappin_active: images[`${media.type}_mappin_active`],
      })),
  },
  getters: {
    mediaSet: (state) => state.mediaSet,
    mediaByTypes: (state) => state.mediaSet.reduce((acc, media) => {
      acc[media.type] = media;
      if (Array.isArray(media.additional)) {
        media.additional.forEach((type) => { acc[type] = media; });
      }
      return acc;
    }, {}),
    extendMediaTypesWithAdditional: (state) => (mediaTypes) => state.mediaSet.reduce(
      (acc, { type, additional }) => (mediaTypes.includes(type) && additional?.length ? [...acc, ...additional] : acc),
      [...mediaTypes],
    ),
    isMediaTypeAdditional: (state, getters) => (type) => getters.mediaByTypes[type].type !== type,
  },
  actions: {},
  mutations: {},
};
