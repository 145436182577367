import Vue from 'vue';
import vSelect from 'vue-select';
import App from './App';
import router from './router';
import store from './store';
import digitGrouping from './helpers/digitGrouping';

import styles from './assets/scss/main.scss';

Vue.config.productionTip = false;
Vue.component('v-select', vSelect);

Vue.filter('thousands', (value) => {
  const validValue = digitGrouping(value, undefined, '.', ',');
  return `${validValue}`;
});

Vue.filter('euro', (value) => {
  const validValue = digitGrouping(value, 2, '.', ',');
  return `\u20AC ${validValue}`;
});

store.dispatch('getDwmPlan').then(() => {
  new Vue({
    router,
    store,
    styles,
    render: (h) => h(App),
  }).$mount('#app');
});
