import axios from 'axios';

const baseHost = process.env.VUE_APP_BASE_HOST;
const requestHost = process.env.VUE_APP_REQUEST_HOST;

const apiBase = axios.create({ baseURL: baseHost });
const apiRequest = axios.create({ baseURL: requestHost });

apiBase.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);
apiRequest.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error),
);

apiBase.interceptors.response.use(
  (response) => ((response.status === 200 || response.status === 201)
    ? Promise.resolve(response.data)
    : Promise.reject(response.data)),
  (error) => Promise.reject(error),
);
apiRequest.interceptors.response.use(
  (response) => ((response.status === 200 || response.status === 201)
    ? Promise.resolve(response.data)
    : Promise.reject(response.data)),
  (error) => Promise.reject(error),
);

const API_ENDPOINTS = [
  { key: 'searchCities', query: '/v1/ort/search' },
  { key: 'getDwmPlan', query: '/v1/dwm-plan' },
  { key: 'getMarkersByCitiesAndYears', query: '/kundentool/pins/by-cities', method: 'post' },
  { key: 'getMarkersByIds', query: '/kundentool/pins/by-ids', method: 'post' },
  {
    key: 'getRequestCsv',
    query: '/files/get-csv',
    method: 'post',
    api: apiRequest,
  },
  {
    key: 'submitRequest',
    query: '/contact',
    method: 'post',
    api: apiRequest,
  },
];

export default API_ENDPOINTS.reduce((acc, {
  key,
  query,
  method = 'get',
  api = apiBase,
}) => {
  acc[key] = (params) => api[method](query, params);
  return acc;
}, {});
