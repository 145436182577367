<template>
  <div
    class="modal-image"
    @click.self="closeModal"
  >
    <div class="modal-image__header">
      <span
        class="modal-image__close icon icon--cros"
        @click="closeModal"
      />
    </div>
    <img
      class="modal-image__img"
      :src="image"
    >
  </div>
</template>

<script>
export default {
  props: {
    image: {
      type: String,
      default: '#',
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-image {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  background-color: $modal-image-bg-color;

  &__header {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    top: 0;
    height: 50px;
    width: 100%;
    padding-right: 20px;
    background-color: $modal-image-header-bg-color;
  }

  &__close {
    cursor: pointer;
    color: $gray-200;
    font-size: 18px;

    &:hover {
      font-size: 24px;
    }
  }

  &__img {
    max-width: 90%;
    max-height: 100%;

    @include media-bp(mob) {
      max-width: 100%;
    }
  }
}
</style>
