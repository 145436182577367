import moment from 'moment-mini';
import api from '@/api';

const DWM_TYPES = Object.freeze({
  decade: {
    blocks: ['A', 'B', 'C'],
    maxDays: 11,
  },
  week: {
    blocks: ['W'],
  },
  month: {
    blocks: ['M'],
  },
});

const SHIFT_DAYS = +process.env.VUE_APP_SHIFT_DAYS;
const START_DATE = moment().add(SHIFT_DAYS, 'days').startOf('day');

const isPeriodFitDateRange = (period, { from, to }) => period.from.isSameOrAfter(from, 'd')
  && period.to.isSameOrBefore(to, 'd');

const filterDwmPlanByDateRange = (dwmPlan, { from, to }) => {
  const isDwmValidFunc = (dwm) => isPeriodFitDateRange(dwm.period, { from, to });
  return dwmPlan.filter(isDwmValidFunc);
};

const filterDwmPlanByBlocks = (dwmPlan, block) => {
  const blockList = Array.isArray(block) ? block : [block];
  const isDwmValidFunc = (dwm) => blockList.includes(dwm.block);

  return dwmPlan.filter(isDwmValidFunc);
};

const filterDwmPlanByType = (dwmPlan, type) => filterDwmPlanByBlocks(dwmPlan, DWM_TYPES[type].blocks);

const filterDwmPlanByYears = (dwmPlan, year) => {
  const yearsList = Array.isArray(year) ? year : [year];
  const isDwmValidFunc = (dwm) => yearsList.includes(dwm.year);

  return dwmPlan.filter(isDwmValidFunc);
};

const sortDwmList = (dwmPlan = []) => [...dwmPlan].sort((a, b) => {
  if (a.year < b.year) return -1;
  if (a.year > b.year) return 1;

  if (a.type < b.type) return -1;
  if (a.type > b.type) return 1;

  if (a.number < b.number) return -1;
  if (a.number > b.number) return 1;

  if (a.block < b.block) return -1;
  if (a.block > b.block) return 1;

  return 0;
});

export default {
  state: {
    fullDwmPlan: [],
    dwmTypes: DWM_TYPES,
  },
  getters: {
    actualYears: (state) => Array.from(new Set(state.fullDwmPlan.map(({ year }) => year))).sort(),

    firstAllowedDate: (state) => {
      const dwmPlanFirstDate = moment.min(...state.fullDwmPlan.map(({ period }) => period.from));

      return moment.max(dwmPlanFirstDate, START_DATE);
    },

    lastAllowedDate: (state) => {
      const dwmPlanLastDate = moment.max(...state.fullDwmPlan.map(({ period }) => period.to));

      return moment.max(dwmPlanLastDate, moment(START_DATE).endOf('year').startOf('day'));
    },

    actualDwmPlan: (state, getters) => {
      const from = getters.firstAllowedDate;
      const to = getters.lastAllowedDate;

      return filterDwmPlanByDateRange(state.fullDwmPlan, { from, to });
    },

    filterDwmPlanByDateRange: () => filterDwmPlanByDateRange,
    filterDwmPlanByBlocks: () => filterDwmPlanByBlocks,
    filterDwmPlanByType: () => filterDwmPlanByType,
    filterDwmPlanByYears: () => filterDwmPlanByYears,

    sortDwmList: () => sortDwmList,

    actualDPlan: (_, getters) => getters.filterDwmPlanByType(getters.actualDwmPlan, 'decade'),
    actualWPlan: (_, getters) => getters.filterDwmPlanByType(getters.actualDwmPlan, 'week'),
    actualMPlan: (_, getters) => getters.filterDwmPlanByType(getters.actualDwmPlan, 'month'),

    criteriaDwmPlan: (_, getters) => {
      const criterion = getters.currentCriteria.dwm;

      if (criterion.isDateRange) {
        if (!criterion.dateRange) return [];

        const [from, to] = criterion.dateRange.split('-').map((date) => moment(date, 'DD.MM.YYYY'));

        return filterDwmPlanByDateRange(getters.actualDwmPlan, { from, to });
      }

      const dwmList = (criterion.dwmList || [])
        .flatMap(({ year, type, number }) => getters.actualDwmPlan
          .filter((dwm) => dwm.year === +year && dwm.type === type && dwm.number === number));

      return sortDwmList(dwmList) || [];
    },

    getDwmItem: (state, getters) => ({ number, block, year = getters.actualYears[0] }) => state.fullDwmPlan
      .find((item) => item.number === number && item.block === block && item.year === year),

    getDwmInfo: () => (dwm) => {
      const { block, period } = dwm;
      const { from, to } = period;

      return (from && to)
        ? `Block ${block}: ${from.format('DD.MM.YYYY')} - ${to.format('DD.MM.YYYY')}`
        : '';
    },

    getDwmPeriodInfo: () => (dwm) => {
      const { period } = dwm;
      const { from, to, days } = period;

      return (from && to)
        ? `${from.format('DD.MM.YYYY')}\u00A0-\u00A0${to.format('DD.MM.YYYY')}\u00A0|\u00A0${days}\u00A0Tage`
        : '';
    },

    isDwmLockedForMarker: (_, getters) => ({ dwm, marker, dwmPlan = getters.actualDwmPlan }) => {
      const { year, block, number } = dwm;

      if (!dwmPlan.includes(dwm)) return true;
      if (marker.data?.[year]?.block !== block) return true;

      const freeSign = (marker.data?.[year]?.frei || '')[number - 1];

      if (freeSign !== block) return true;

      return false;
    },
  },
  actions: {
    getDwmPlan({ commit, dispatch }) {
      return api.getDwmPlan().then(({ items }) => {
        const dwmPlan = Object.entries(items)
          .filter(([year]) => year >= START_DATE.year())
          .flatMap(([year, yearPlan]) => Object.entries(yearPlan)
            .flatMap(([block, blockYearPlan]) => Object.entries(blockYearPlan)
              .map(([number, { von, bis, tage }]) => {
                const type = Object.entries(DWM_TYPES)
                  .find(([, { blocks }]) => blocks.includes(block))[0];

                const period = {
                  from: moment(von, 'DD.MM.YYYY'),
                  to: moment(bis, 'DD.MM.YYYY'),
                  days: +tage,
                };

                if (DWM_TYPES[type].maxDays < period.days) period.maxDays = DWM_TYPES[type].maxDays;

                const dwm = {
                  year: +year,
                  type,
                  block,
                  number,
                  period,
                };

                Object.freeze(dwm);

                return dwm;
              })));

        commit('SAVE_FULL_DWM_PLAN', sortDwmList(dwmPlan));
        // updateCurrentDwmCriteria will update dwmList according to actualDwmPlan
        dispatch('updateCurrentDwmCriteria');
      });
    },
  },
  mutations: {
    SAVE_FULL_DWM_PLAN(state, dwmPlan) {
      state.fullDwmPlan = dwmPlan;
    },
  },
};
