<template>
  <transition name="fade">
    <div
      v-if="isOpened"
      ref="backdrop"
      class="app-modal"
      @click.self="onBackdropClick"
    >
      <image-modal
        v-if="isImage"
        :image="content"
        @close="closeModal"
      />
      <message-modal
        v-if="isMessage"
        :message="content"
        @close="closeModal"
      />
    </div>
  </transition>
</template>

<script>
import eventbus from '@/utils/eventbus';

import imageModal from './components/image';
import messageModal from './components/message';

export default {
  components: {
    imageModal,
    messageModal,
  },
  data() {
    return {
      type: null,
      content: null,
      isBackdropClose: false,
    };
  },
  computed: {
    isOpened() {
      return this.type && this.content;
    },
    isImage() {
      return this.type === 'image';
    },
    isMessage() {
      return this.type === 'message';
    },
  },
  created() {
    eventbus.$on('open-image', this.openImage);
    eventbus.$on('open-message', this.openMessage);
  },
  methods: {
    openImage(image) {
      this.type = 'image';
      this.content = image;
    },
    openMessage(message) {
      this.type = 'message';
      this.content = message;
    },
    onBackdropClick() {
      if (this.isBackdropClose) this.closeModal();
    },
    closeModal() {
      this.type = null;
      this.content = null;
    },
    closeSelfModal() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.app-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px;

  background-color: $modal-backdrop-color;
  z-index: 999;
}
</style>
