<template>
  <div id="app">
    <router-view />
    <app-modal />
  </div>
</template>

<script>
import AppModal from '@/components/app-modal';

export default {
  components: {
    AppModal,
  },
};
</script>

<style lang="scss">
</style>
