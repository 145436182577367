<template>
  <div
    class="modal-message"
    :class=" size ? `modal-message--${size}` : '' "
  >
    <div class="modal-message__header">
      {{ header }}
    </div>
    <div
      v-if="body"
      class="modal-message__body"
    >
      {{ body }}

      <img
        v-if="image"
        class="modal-message__image"
        :src="image"
        alt="Warning Image"
      >
    </div>
    <div class="modal-message__footer">
      <button
        v-for="({ label, onClick }, key) in buttons"
        :key="key"
        class="btn"
        @click="() => onClick()"
      >
        {{ label }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    header() {
      return this.message.header || 'Warnung';
    },
    body() {
      return this.message.body;
    },
    image() {
      return this.message.image;
    },
    size() {
      return this.message.size;
    },
    buttons() {
      return this.message.buttons
        ? Object.entries(this.message.buttons).map(([label, func]) => ({
          label,
          onClick: () => {
            func();
            this.closeModal();
          },
        }))
        : [{ label: 'OK', onClick: () => this.closeModal() }];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss">
.modal-message {
  flex: 1 1 auto;

  max-width: 600px;
  &--small,
  &--sm {
    max-width: 300px;
  }

  &--large,
  &--lg {
    max-width: 900px;
  }

  display: flex;
  flex-direction: column;
  background-color: $modal-message-bg-color;
  background-clip: padding-box;

  box-shadow: $modal-message-box-shadow;
  border: $modal-message-border;
  border-radius: 6px;

  overflow: hidden;

  &__header {
    width: 100%;
    padding: 20px 15px;

    color: $modal-message-header-color;
    background-color: $modal-message-header-bg-color;
    border-bottom: $modal-message-header-border;

    text-align: center;

    position: relative;

    text-transform: uppercase;
  }

  &__body {
    padding: 15px;
    line-height: 1.6;
    white-space: pre-line;
  }

  &__image {
    display: block;
    width: 100%;

    margin: 12px 0;
    padding: 20px 6px;

    box-shadow: $modal-message-image-box-shadow;
  }

  &__footer {
    display: flex;
    justify-content: flex-end;

    padding: 15px;
    border-top: $modal-message-footer-border;

    .btn + .btn {
      margin-left: 8px;
    }
  }
}
</style>
