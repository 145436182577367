import moment from 'moment-mini';

const CRITERIA = {
  media: {
    defaultValue: ['GF'],
    validator: (value) => (value.length ? '' : 'Mindenstens einen Medien auswählen'),
    toQuery: (value) => [['media', value?.join?.(',')]],
    fromQuery: (query) => ({ key: 'media', value: query?.media?.split?.(',') }),
  },
  cities: {
    defaultValue: [],
    validator: (value) => (value.length ? '' : 'Bitte einen Ort auswählen'),
    toQuery: (value) => [['ortnr', value.map(({ ortnr }) => ortnr).join(',')]],
    fromQuery: (query) => ({ key: 'ortnr', value: query?.ortnr?.split?.(',') }),
  },
  dwm: {
    defaultValue: { isDateRange: false, minDays: 12 },
    validator: (value) => {
      if (value.isDateRange) {
        if (!value.dateRange) return `Bitte den Zeitraum auswählen (mindenstens ${value.minDays} Tagen)`;

        const [from, to] = value.dateRange.split('-').map((d) => moment(d, 'DD.MM.YYYY'));
        const diffDays = to.diff(from, 'days');

        if (diffDays + 1 < value.minDays) return `Mindenstens ${value.minDays} Tagen auswählen`;
        return '';
      }

      return (value?.dwmList?.length ? '' : 'Mindenstens einen Dekaden auswählen');
    },
    toQuery: (value) => {
      if (value?.isDateRange) return [['dwm.daterange', value?.dateRange]];

      const dwmListStructure = (value.dwmList || []).reduce((acc, dwm) => {
        const { type, year, number } = dwm;
        if (!acc[type]) acc[type] = {};
        if (!acc[type][year]) acc[type][year] = [];
        if (!acc[type][year].includes(number)) acc[type][year].push(number);

        acc[type][year].sort();

        return acc;
      }, {});

      return Object.entries(dwmListStructure).map(([type, yearsNumbers]) => {
        const code = Object.entries(yearsNumbers)
          .map(([year, numbers]) => [year, ...numbers].join(','))
          .join('|');

        return [`dwm.${type}`, code];
      });
    },
    fromQuery: (query) => {
      if (query['dwm.daterange']) {
        return [
          { key: 'dwm.isDateRange', value: true },
          { key: 'dwm.dateRange', value: query['dwm.daterange'] },
        ];
      }
      const dwmList = ['decade', 'week', 'month'].flatMap((type) => (query[`dwm.${type}`] || '')
        .split('|')
        .flatMap((yearNumbers) => {
          const [year, ...numbers] = yearNumbers.split(',');
          return numbers.map((number) => ({ type, year, number }));
        }));

      return dwmList.length
        ? [
          { key: 'dwm.isDateRange', value: false },
          { key: 'dwm.dwmList', value: dwmList },
        ] : { key: 'dwm' };
    },
  },
};

const defaultCriteria = Object.entries(CRITERIA)
  .reduce((acc, [key, { defaultValue }]) => ({ ...acc, [key]: defaultValue }), {});

const criteriaToQuery = (criteria) => Object.fromEntries(
  Object.entries(criteria)
    .flatMap(([key, value]) => CRITERIA[key].toQuery(value))
    .filter(([queryKey, queryValue]) => queryKey && queryValue),
);

export default {
  state: {
    currentCriteria: JSON.parse(JSON.stringify(defaultCriteria)),
    criteriaErrors: Object.fromEntries(Object.keys(CRITERIA).map((key) => [key, ''])),
  },
  getters: {
    currentCriteria: (state) => state.currentCriteria,
    currentCriteriaQuery: (_, getters) => criteriaToQuery(getters.currentCriteria),

    criteriaErrors: (state) => state.criteriaErrors,
    currentCriteriaErrors: (state) => Object.fromEntries(Object.entries(state.currentCriteria)
      .map(([key, value]) => [key, CRITERIA[key].validator(value)])),

    isCurrentCriteriaValid: (_, getters) => Object.values(getters.currentCriteriaErrors).every((error) => !error),
  },
  actions: {
    setCriteriaFromQuery({ getters, commit, dispatch }, query = {}) {
      if (Object.keys(query).length) {
        commit('CLEAR_CRITERIA');
        Object.keys(CRITERIA)
          .flatMap((key) => CRITERIA[key].fromQuery(query))
          .forEach(({ key, value }) => {
            if (key && value != null) {
              dispatch('updateCriteria', { key, value });
            }
          });
      }
      return getters.currentCriteriaQuery;
    },
    validateCriteria({ getters, commit }, keys = Object.keys(CRITERIA)) {
      const errors = {
        ...getters.criteriaErrors,
        ...keys.reduce((acc, key) => ({ ...acc, [key]: getters.currentCriteriaErrors[key] }), {}),
      };

      commit('UPDATE_CRITERIA_ERRORS', errors);
    },
    updateCriteria({ getters, commit, dispatch }, { key, value }) {
      switch (value && key) {
        case 'ortnr':
          commit('UPDATE_CRITERIA', { key: 'cities', value: value.map((ortnr) => ({ ortnr })) });

          Promise.all(value.map((ortnr) => dispatch('getCityByOrtnr', ortnr)))
            .then((cities) => {
              commit('UPDATE_CRITERIA', { key: 'cities', value: cities.filter((x) => x) });
            });

          break;
        case 'media':
          commit('UPDATE_CRITERIA', {
            key,
            value: value.filter((type) => getters.mediaSet.find((media) => media.type === type)),
          });

          break;
        case 'dwm.dwmList':
          commit('UPDATE_CRITERIA', {
            key,
            value: getters.actualDwmPlan.length
              ? value.filter(({ number, type, year }) => getters.actualDwmPlan
                .find((dwm) => dwm.number === number && dwm.type === type && dwm.year === +year))
              : value,
          });

          break;
        case 'dwm.dateRange': {
          const [from, to] = value.split('-').map((d) => moment(d, 'DD.MM.YYYY'));

          const isDateRangeValid = from.isValid()
            && to.isValid()
            && to.isSameOrAfter(from)
            && to.isSameOrAfter(getters.firstAllowedDate);

          if (isDateRangeValid) {
            const actualDateRangeCriteria = [
              moment.max(from, getters.firstAllowedDate),
              moment.min(to, getters.lastAllowedDate),
            ].map((d) => d.format('DD.MM.YYYY')).join('-');

            commit('UPDATE_CRITERIA', { key, value: actualDateRangeCriteria });
          } else {
            commit('CLEAR_CRITERIA', key);
          }

          break;
        }
        default:
          if (value != null) commit('UPDATE_CRITERIA', { key, value });
          else commit('CLEAR_CRITERIA', key);
          break;
      }
    },
    updateCurrentDwmCriteria({ getters, dispatch }) {
      const key = 'dwm.dwmList';
      const value = getters.currentCriteria.dwm.dwmList;
      dispatch('updateCriteria', { key, value });
    },
    clearCriteria({ commit }, key = null) {
      commit('CLEAR_CRITERIA', key);
    },
  },
  mutations: {
    UPDATE_CRITERIA(state, { key, value }) {
      const currentCriteria = { ...state.currentCriteria };
      let pointer = currentCriteria;
      const path = key.split('.');
      while (path.length > 1) {
        const currentKey = path.shift();
        if (!pointer[currentKey]) {
          pointer[currentKey] = {};
        }
        pointer = pointer[currentKey];
      }
      pointer[path[0]] = value;

      state.currentCriteria = currentCriteria;
    },
    CLEAR_CRITERIA(state, key) {
      if (key) {
        const currentCriteria = { ...state.currentCriteria };

        let pointer = currentCriteria;
        let value = defaultCriteria;
        const path = key.split('.');
        while (path.length > 1) {
          const currentKey = path.shift();
          if (!pointer[currentKey]) {
            pointer[currentKey] = {};
          }
          pointer = pointer[currentKey];
          value = value?.[currentKey];
        }
        pointer[path[0]] = value?.[path[0]];

        state.currentCriteria = currentCriteria;
      } else {
        state.currentCriteria = JSON.parse(JSON.stringify(defaultCriteria));
      }
    },
    UPDATE_CRITERIA_ERRORS(state, errors) {
      state.criteriaErrors = errors;
    },
  },
};
