const CART_STORAGE_KEY = 'ktcsk';
const MWST_PROCENTS = +process.env.VUE_APP_MWST_PROCENTS;

const CART_CODE_DELIMITERS = {
  marker: 'M',
  year: '|',
  number: ',',
  date: '-',
};

const decodeCartCode = (cartCode = '') => {
  const res = cartCode.split(CART_CODE_DELIMITERS.marker)
    .reduce((acc, markerCode) => {
      if (markerCode.includes(CART_CODE_DELIMITERS.date)) {
        const [id, from, to] = markerCode.split(CART_CODE_DELIMITERS.date);
        if (!id || !from || !to) return acc;
        const dateRange = [from, to];

        return { ...acc, [id]: { markerId: id, dateRange } };
      }

      const [markerId, ...markerYearCodes] = markerCode.split(CART_CODE_DELIMITERS.year);
      if (!markerId) return acc;

      const selectedDwm = markerYearCodes.flatMap((yearCode) => {
        const [year, ...numbers] = yearCode.split(CART_CODE_DELIMITERS.number);
        return numbers.map((number) => ({ year: +year, number }));
      });

      return { ...acc, [markerId]: { markerId, selectedDwm } };
    }, {});

  return res;
};
const encodeCart = (cartData) => Object.values(cartData)
  .map(({ markerId, selectedDwm, dateRange }) => {
    if (dateRange) {
      return [markerId, ...dateRange].join(CART_CODE_DELIMITERS.date);
    }

    const numbersByYears = selectedDwm.reduce((acc, { year, number }) => {
      if (!acc[year]) acc[year] = [];
      acc[year].push(number);
      return { ...acc };
    }, {});

    const markerYearCodes = Object.entries(numbersByYears)
      .map(([year, numbers]) => [year, ...numbers].join(CART_CODE_DELIMITERS.number));

    return markerYearCodes.length
      ? [markerId, ...markerYearCodes].join(CART_CODE_DELIMITERS.year)
      : '';
  }).filter((x) => x).join(CART_CODE_DELIMITERS.marker);

export default {
  state: {
    cartData: decodeCartCode(localStorage.getItem(CART_STORAGE_KEY) || ''),
  },
  getters: {
    cartCode: (state) => encodeCart(state.cartData),
    cartData: (state) => state.cartData,
    cartMarkersList: (state) => Object.values(state.cartData).map(({ marker }) => marker).filter((x) => x),
    cartCities: (_, getters) => Array.from(new Set(getters.cartMarkersList.map(({ ona }) => ona))).sort(),
    isCartActual: (_, getters) => Object.values(getters.cartData)
      .every(({ marker, selectedDwm = [] }) => selectedDwm
        .every((dwm) => !getters.isDwmLockedForMarker({ dwm, marker }))),

    mwstProcents: () => MWST_PROCENTS,
    getMarkerDwmCartSum: (_, getters) => ({ marker, dwm }) => {
      const markerCartData = getters.cartData[marker.id];
      if (markerCartData?.selectedDwm?.includes?.(dwm) && dwm?.period?.days) {
        const { prs = 0 } = markerCartData.marker?.data?.[dwm.year] || {};

        return (dwm.period.maxDays && dwm.period.maxDays < dwm.period.days)
          ? prs * dwm.period.maxDays
          : prs * dwm.period.days;
      }
      return 0;
    },
    getMarkerCartSum: (_, getters) => (marker) => {
      const markerCartData = getters.cartData[marker.id];

      const sum = markerCartData?.marker
        ? (markerCartData?.selectedDwm || [])
          .reduce((acc, dwm) => acc + getters.getMarkerDwmCartSum({ marker, dwm }), 0)
        : 0;

      return sum;
    },
    cartTotalData: (_, getters) => {
      const markersCount = getters.cartMarkersList.length;
      const totalNetto = getters.cartMarkersList.reduce((acc, marker) => acc + getters.getMarkerCartSum(marker), 0);
      const totalMwst = Math.round(totalNetto * getters.mwstProcents) / 100;
      const total = totalNetto + totalMwst;

      return {
        markersCount,
        totalNetto,
        totalMwst,
        total,
      };
    },
  },
  actions: {
    processCartData({ getters, commit, dispatch }) {
      const ids = Object.keys(getters.cartData);

      const years = Array.from(new Set(
        Object.values(getters.cartData).flatMap(({ selectedDwm }) => (selectedDwm || []).map(({ year }) => year)),
      ));

      return dispatch('getMarkersByIds', { ids, years }).then((markers) => {
        markers.forEach((marker) => {
          const dateRange = getters.cartData[marker.id]?.dateRange;
          if (dateRange) {
            commit('UPDATE_MARKER_CART', { marker, dateRange });
          } else {
            const selectedDwm = (getters.cartData[marker.id]?.selectedDwm || [])
              .map(({ year, number }) => {
                const { block } = marker.data[year];
                return getters.getDwmItem({ year, number, block });
              }).filter((dwm) => dwm);

            commit('UPDATE_MARKER_CART', { marker, selectedDwm });
          }
        });
      });
    },
    updateCartDataFromCode({ dispatch }, cartCode) {
      const cartData = decodeCartCode(cartCode);
      return dispatch('updateCartData', cartData);
    },
    updateCartData({ commit, dispatch }, cartData) {
      commit('UPDATE_CART', cartData);
      dispatch('storeCartCode');
    },
    updateMarkerCart({ commit, dispatch }, { marker, selectedDwm, dateRange }) {
      if (!selectedDwm?.length && !dateRange) {
        commit('DELETE_MARKER_CART', { marker });
      } else {
        commit('UPDATE_MARKER_CART', { marker, selectedDwm, dateRange });
      }
      dispatch('storeCartCode');
    },
    deleteMarkerCart({ commit, dispatch }, { marker }) {
      commit('DELETE_MARKER_CART', { marker });
      dispatch('storeCartCode');
    },
    storeCartCode({ getters }) {
      localStorage.setItem(CART_STORAGE_KEY, getters.cartCode);
    },
    clearCart({ commit, dispatch }) {
      commit('CLEAR_CART');
      dispatch('storeCartCode');
    },
  },
  mutations: {
    UPDATE_CART(state, cartData) {
      state.cartData = cartData;
    },
    UPDATE_MARKER_CART(state, { marker, selectedDwm, dateRange }) {
      state.cartData = {
        ...state.cartData,
        [marker.id]: {
          markerId: marker.id,
          marker,
          selectedDwm,
          dateRange,
        },
      };
    },
    DELETE_MARKER_CART(state, { marker }) {
      const cartData = { ...state.cartData };
      delete cartData[marker.id];
      state.cartData = cartData;
    },
    CLEAR_CART(state) {
      state.cartData = {};
    },
  },
};
