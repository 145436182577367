/**
 * The file enables `@/store/index.js` to import all vuex modules in a one-shot manner.
 * There should not be any reason to edit this file.
 */

const filePaths = require.context('.', false, /\.js$/);
const modules = {};

filePaths.keys().forEach((path) => {
  const moduleName = path.replace(/(\.\/|\.js)/g, '');

  if (path !== './index.js') {
    modules[moduleName] = filePaths(path).default;
  }
});

export default modules;
