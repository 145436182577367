import api from '@/api';

const fixLatLng = (marker) => {
  const lat = marker.lat.replace(',', '.');
  const lng = marker.lng.replace(',', '.');
  return { lat: Math.max(+lat, +lng), lng: Math.min(+lat, +lng) };
};

/** @todo process block and price by year in all components */
const processMarkers = (marker, type, year) => ({
  ...marker,
  ...marker.data[year],
  position: fixLatLng(marker),
  tpNative: marker.tp,
  tp: type || marker.tp,
  bl: marker.bl === 'B' || marker.bl === 'H',
});

const arrIncludesSubarray = (arr = [], subArr = []) => {
  const result = subArr.every([].includes.bind(arr));
  return result;
};

const isMatch = (value, filter) => value.toLowerCase().includes(filter.toLowerCase());

const filterWith = (filters) => (marker) => {
  const {
    address,
    district,
    zip,
    backlight,
  } = filters;

  if (address && !isMatch(marker.adr, address)) return false;
  if (district && !isMatch(marker?.otl, district)) return false;
  if (zip && !isMatch(marker.plz, zip)) return false;
  if (backlight && (backlight === 'yes') !== marker.bl) return false;

  return true;
};

const FILTERS_DEFAULT = {
  address: '',
  district: '',
  zip: '',
  backlight: '',
};

export default {
  state: {
    markers: [],
    lastLoadMarkersPayload: null,
    filters: JSON.parse(JSON.stringify(FILTERS_DEFAULT)),
  },
  getters: {
    allMarkers: (state, getters) => state.markers
      .filter(({ tp }) => getters.currentCriteria.media.includes(tp))
      .sort((a, b) => (a.adr > b.adr ? 1 : -1)),
    filters: (state) => state.filters,
    filtersCount: (state) => Object.values(state.filters).filter((x) => x).length,
    filteredMarkers: (state, getters) => (getters.filtersCount === 0
      ? getters.allMarkers
      : getters.allMarkers.filter((marker) => {
        const result = filterWith(getters.filters)(marker);
        return result;
      })),
    isIncludedInLastPayload: (state) => (payload) => {
      const { lastLoadMarkersPayload } = state;

      return lastLoadMarkersPayload
        && arrIncludesSubarray(lastLoadMarkersPayload.ortnr, payload.ortnr)
        && arrIncludesSubarray(lastLoadMarkersPayload.types, payload.types)
        && arrIncludesSubarray(lastLoadMarkersPayload.years, payload.years);
    },
  },
  actions: {
    loadMarkers({ getters, dispatch }, criteria) {
      const { cities, media } = criteria || getters.currentCriteria;
      const payload = {
        ortnr: cities?.map(({ ortnr }) => ortnr),
        types: getters.extendMediaTypesWithAdditional(media),
        years: getters.actualYears,
      };

      if (getters.isIncludedInLastPayload(payload)) {
        return Promise.resolve();
      }
      return api.getMarkersByCitiesAndYears(payload)
        .then(({ items }) => items
          .filter(({ tp }) => getters.mediaByTypes[tp])
          .map((marker) => processMarkers(marker, getters.mediaByTypes[marker.tp].type, getters.actualYears[0]))
          .filter(({ block, tp }) => block || getters.mediaByTypes[tp].allowOffer))
        .then((markers) => {
          dispatch('updateMarkers', { markers, payload });
          dispatch('clearFilters');
        });
    },
    getMarkersByIds({ getters }, { ids, years = getters.actualYears }) {
      const payload = { ids, years };

      return ids.length
        ? api.getMarkersByIds(payload)
          .then(({ items }) => items.map((marker) => {
            const { type } = getters.mediaByTypes[marker.tp];
            const [year] = years;

            return processMarkers(marker, type, year);
          }))
        : Promise.resolve([]);
    },
    updateMarkers({ commit }, { markers, payload = null }) {
      commit('UPDATE_MARKERS', markers);
      commit('UPDATE_LAST_PAYLOAD', payload);
    },
    updateFilters({ commit }, filters) {
      commit('UPDATE_FILTERS', filters);
    },
    clearFilters({ commit }) {
      commit('UPDATE_FILTERS', JSON.parse(JSON.stringify(FILTERS_DEFAULT)));
    },
  },
  mutations: {
    UPDATE_MARKERS(state, markers) {
      state.markers = markers;
    },
    UPDATE_LAST_PAYLOAD(state, payload) {
      state.lastLoadMarkersPayload = JSON.parse(JSON.stringify(payload));
    },
    UPDATE_FILTERS(state, filters) {
      state.filters = { ...state.filters, ...filters };
    },
  },
};
